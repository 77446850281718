import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { API_URL } from "url";

function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const submitData = async (event) => {
    event.preventDefault();

    if (!password || !confirmPassword) {
      setError("Please fill in all fields");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setError("");
    setSubmitButtonDisabled(true);
    setMessage("");

    try {
      const response = await fetch(`${API_URL}/auth/resetPassword/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (data.success) {
        setSubmitButtonDisabled(false);
        setMessage("Password has been reset successfully. You can now log in.");
        setPassword("");
        setConfirmPassword("");
        // Redirect to login page after successful reset
        setTimeout(() => navigate("/login"), 3000);
      } else {
        setSubmitButtonDisabled(false);
        setError(data.message || "Error resetting password. Please try again.");
      }
    } catch (error) {
      setSubmitButtonDisabled(false);
      setError("An error occurred. Please try again.");
      console.error("Fetch error:", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={submitData}>
            <MDBox mb={2}>
              <input
                type="password"
                className="form-control"
                placeholder="New Password"
                style={{ height: "50px" }}
                name="password"
                value={password}
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                style={{ height: "50px" }}
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                disabled={submitButtonDisabled}
              >
                Reset Password
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1}>
              {message && <p style={{ color: "green" }}>{message}</p>}
              {error && <p style={{ color: "red" }}>{error}</p>}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
