// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { API_URL } from "url";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./blogdetails.css";
function Overview() {
  let location = useLocation();
  const slug = location?.state?.slug;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getBlogData().then(() => {});
  }, []);
  const getBlogData = async () => {
    try {
      const response = await fetch(`${API_URL}/blog/bySlug/${slug}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        next: { revalidate: 0 },
        cache: "no-store",
      });
      const data = await response.json();
      if (data.success) {
        setLoading(false);
        const blog = data.data;
        setData(blog);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "500px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <MDBox mb={2}>
                  <MDBox>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Blog
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      mt={5}
                      sx={{
                        maxWidth: "750px",
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "20px",
                        padding: "30px",
                      }}
                    >
                      <MDBox mt={5}>
                        <img
                          src={data?.image}
                          alt="blog_img"
                          style={{ borderRadius: "8px", maxWidth: "750px", maxHeight: "500px" }}
                        />
                      </MDBox>
                      <MDBox mt={3}>
                        <MDTypography variant="h1">{data?.title}</MDTypography>
                      </MDBox>
                      <MDBox
                        sx={{
                          width: "100%",
                        }}
                      >
                        <MDBox
                          color={"text"}
                          id="blog-content"
                          dangerouslySetInnerHTML={{
                            __html: data?.details,
                          }}
                        />
                      </MDBox>
                      <MDBox
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Link to={"/all-blogs"}>
                          <MDButton variant="gradient" color="info">
                            Back
                          </MDButton>
                        </Link>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
