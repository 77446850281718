// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { exportToExcel } from "../../utils/dataToexcel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useRef, useState } from "react";

// Data
import authorsTableData from "layouts/subscription/data/authorsTableData";

function Tables() {
  const [selectedOption, setSelectedOption] = useState("xlsx");
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };
  const { columns, rows, totalCount, pagecount, setPage, userData } = authorsTableData();
  const tableRef = useRef(null);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users Table
                </MDTypography>
              </MDBox>

              <MDBox>
                <select
                  value={selectedOption}
                  onChange={handleSelect}
                  style={{
                    marginLeft: "20px",
                    marginTop: "20px",
                    borderRadius: "8px",
                    padding: "4px 12px",
                    marginBottom: "10px",
                  }}
                >
                  <option value="xlsx">xlsx</option>
                  <option value="xls">xls</option>
                </select>
              </MDBox>
              {selectedOption === "xlsx" ? (
                <MDBox ml={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => exportToExcel(userData, "excelSheet.xlsx")}
                  >
                    Export to .xlsx
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox>
                  <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-primary mt-2 ms-3"> Export to .xls </button>
                  </DownloadTableExcel>
                </MDBox>
              )}

              <MDBox pt={3} ref={tableRef}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  pageSize={pagecount}
                  totalCount={totalCount}
                  setPage={setPage}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
