import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Card, CircularProgress, Box, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import axiosInstance from "api/axiosInstance";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { API_URL } from "url";
import { toast } from "react-toastify";
function DepartmentList() {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newDepartment, setNewDepartment] = useState({
    departmentName: "",
  });

  // Fetch departments from API
  useEffect(() => {
    setLoading(true);
    fetchDepartments().then(() => setLoading(false));
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await fetch(`${API_URL}/job/getAllDepartment`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
      });
      const data = await response.json();
      if (data.success) {
        setDepartments(data.data);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  const handleDelete = async (id) => {
    const confirmed = window?.confirm("Are you sure want to delete?");
    if (confirmed) {
      try {
        const res = await axiosInstance.delete(`${API_URL}/job/deleteDepartment/${id}`);

        console.log("ff", res);
        if (res?.data?.success) {
          toast.success(res?.data?.message);
          setDepartments(departments.filter((dept) => dept._id !== id));
        }
        // Remove the deleted department from the state
      } catch (error) {
        toast.success(error.response?.data?.message);
        console.error("Error deleting department:", error);
      }
    }
  };
  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDepartment({
      ...newDepartment,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post(`${API_URL}/job/PostDepartment`, newDepartment);

      if (res?.data.success) {
        // Update departments list
        fetchDepartments();
        // Reset form fields
        setNewDepartment({ departmentName: "" });
      }
    } catch (error) {
      console.error("Error creating department:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "500px",
                    maxWidth: "750px",
                    margin: "auto",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <MDBox
                  mb={2}
                  sx={{
                    maxWidth: "750px",
                    margin: "auto",
                    width: "100%",
                    padding: "50px",
                  }}
                >
                  {/* Create Department form */}
                  <form onSubmit={handleSubmit}>
                    <MDBox mt={5}>
                      <MDTypography variant="h6" color="info">
                        Create Department
                      </MDTypography>
                      <TextField
                        name="departmentName"
                        label="Department Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={newDepartment.departmentName}
                        onChange={handleInputChange}
                        required
                      />

                      <MDButton type="submit" variant="gradient" color="info">
                        Create
                      </MDButton>
                    </MDBox>
                  </form>
                  {/* Departments list */}
                  <MDBox mt={5}>
                    {departments?.map((dept) => (
                      <MDBox
                        key={dept.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <MDBox mt={3}>
                          <MDTypography variant="p">{dept.departmentName}</MDTypography>
                        </MDBox>

                        {/* Edit and delete buttons */}
                        <MDBox mt={2}>
                          <MDButton
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(dept._id)}
                          >
                            Delete
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    ))}
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DepartmentList;
