// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { Link } from "react-router-dom";
import { useRef } from "react";
// Data
import authorsTableData from "layouts/jobPage/viewJobs/data/authorsTableData";

function Tables() {
  const { columns, rows, totalCount, pagecount, setPage } = authorsTableData();
  const tableRef = useRef(null);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Jobs Table
                </MDTypography>
              </MDBox>

              <MDBox sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Link to={"/post-job"}>
                  {" "}
                  <button className="btn btn-primary mt-2 ms-3"> Post Job </button>
                </Link>
                <Link to={"/job-department"}>
                  {" "}
                  <button className="btn btn-primary mt-2 ms-3"> Add Department </button>
                </Link>
              </MDBox>
              <MDBox pt={3} ref={tableRef}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  pageSize={pagecount}
                  totalCount={totalCount}
                  setPage={setPage}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
