import React from "react";
import { Link } from "react-router-dom";
import "./notfound.css"; // Import your CSS file for styling
import notFound from "../assets/images/not-found.jpg";
const NotFound = () => {
  return (
    <div className="not-found-container">
      <img className="not-found-image" src={notFound} alt="Lost Person" />
      <h1 className="not-found-title">Oops! Page Not Found</h1>
      <p className="not-found-text">
        It seems you've wandered off the path. Don't worry, let's get you back on track!
      </p>

      <div className="not-found-buttons">
        <Link to="/" className="not-found-link">
          <button className="not-found-button">Go Home</button>
        </Link>
        <button
          className="not-found-button"
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
