import { useState, useEffect, useMemo } from "react";
import BlogPost from "./layouts/blog/data/blogPost";
import JobPost from "./layouts/jobPage/section/postJob";
import JobDepartment from "./layouts/jobPage/viewJobs/data/departments";
import BlogDetails from "./layouts/viewBlogs/data/viewBlogDetails";
import JobDetails from "./layouts/jobPage/viewJobs/data/viewBlogDetails";
import NotFound from "not-found/no-found";
// alert component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// react-router components
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
// jwt decode
import { jwtDecode } from "jwt-decode";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import SignIn from "layouts/authentication/sign-in";
import ForgotPassword from "layouts/authentication/forgotPassword/forgotpasswordStep1";
import ResetPassword from "layouts/authentication/forgotPassword/forgotPasswordStep2";
// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logoLight.png";

export default function App() {
  const navigate = useNavigate();
  const [hasToken, setHasToken] = useState(); // State to track the presence of a token
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Check if there is a token in local storage on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== undefined && localStorage.getItem("user")) {
      setHasToken(token);
    }
  }, []);
  useEffect(() => {
    isAuthenticated();
  }, []);
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (pathname.startsWith("/resetPassword")) {
      return;
    }
    if (!token) {
      navigate("/");
      return;
    }
    try {
      const { exp } = jwtDecode(token);
      console.log("Token expiration time:", exp); // Add this line to see the expiration time
      console.log("Current time:", Date.now() / 1000); // Add this line to see the current time
      if (exp < Date.now() / 1000) {
        // Token expired
        localStorage.clear();
        localStorage.removeItem("token");
        navigate("/");
        window.location.href = "/";
        toast.info("Your session has expired. Please log in again to continue.");
        return;
      }
    } catch (error) {
      console.error("Invalid token:", error); // Log the error for further investigation
    }
  };

  useEffect(() => {
    if (pathname.startsWith("/resetPassword")) {
      return;
    }
    if (pathname === "/") {
      if (hasToken) {
        navigate("/quote-form");
      } else {
        navigate("/");
      }
    }
  }, [pathname, hasToken, navigate]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        // If the route requires authentication and there is no token, do not render

        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ToastContainer />
      {hasToken ? (
        <>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}

          <Routes>
            <Route path="/blog-details" element={<BlogDetails />}></Route>
            <Route path="/job-details" element={<JobDetails />}></Route>
            <Route path="/post-blog" element={<BlogPost />}></Route>
            <Route path="/post-job" element={<JobPost />}></Route>
            <Route path="/job-department" element={<JobDepartment />}></Route>

            {getRoutes(routes)}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}
