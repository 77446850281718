import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { API_URL } from "url";
import { Typography } from "@mui/material";
function Basic() {
  const Navigate = useNavigate();
  const [user, setAddUser] = useState({
    email: "",
    password: "",
  });
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [error, setErroer] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    const filedName = e.target.name;
    const filedValue = e.target.value;

    setAddUser({ ...user, [filedName]: filedValue });
  };
  const submitData = async (event) => {
    event.preventDefault();

    if (!user.email || !user.password) {
      setErroer("Fill all fields");
      return;
    }
    setErroer("");
    setSubmitButtonDisabled(true);
    try {
      const response = await fetch(API_URL + "/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const data = await response.json();

      if (data.sucess) {
        setSubmitButtonDisabled(false);
        localStorage.setItem("token", data?.accessToken);
        localStorage.setItem("user", data?.data?.email);
        // Redirect to the desired page after successful login
        Navigate("/quote-form");
        window.location.href = "/quote-form";
      } else {
        setSubmitButtonDisabled(false);
        setErroer(data?.error);
        console.error("Error fetching data:", data);
      }
    } catch (error) {
      setSubmitButtonDisabled(false);
      setErroer(error?.error);
      console.error("Fetch error:", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Email"
                style={{ height: "50px" }}
                name="email"
                value={user.email}
                onChange={handleChange}
              ></input>
            </MDBox>
            <MDBox mb={2}>
              <input
                type="password"
                placeholder="Password"
                className="form-control "
                id="exampleInputPassword1"
                style={{ height: "50px" }}
                name="password"
                value={user.password}
                onChange={handleChange}
              ></input>
              <Link to={"/forgotPassword"} aria-label="go to forgotPassword page">
                <Typography sx={{ fontSize: "14px" }}>Forgot Passsword</Typography>
              </Link>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={submitData}
                disabled={submitButtonDisabled}
              >
                Sign in
              </MDButton>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <p style={{ color: "red" }}>{error && error}</p>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
