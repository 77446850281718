import axios from "axios";
import { API_URL } from "url";
const axiosInstance = axios.create({
  // Set your base URL here
  baseURL: API_URL,
  // Other axios configurations can be added here
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Get the token from localStorage
    const token = localStorage.getItem("token");
    console.log("sss", token, config);
    // If the token exists, add it to the authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
