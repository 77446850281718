import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import axios from "axios";
import axiosInstance from "api/axiosInstance";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CircularProgress from "@mui/material/CircularProgress";
import { Cloudinary } from "cloudinary-core"; // Import Cloudinary core for configuration
import { toast } from "react-toastify";
const cloudinaryCore = new Cloudinary({ cloud_name: process.env.REACT_APP_CLOUD_NAME }); // Replace "your_cloud_name" with your Cloudinary cloud name

const CreateBlogForm = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const slug = location?.state?.slug;

  const [BlogData, setBlogData] = useState({});
  const [disabled, setDisabled] = useState();
  const [image, setImage] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [details, setDetails] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    categories: "",
    author: "",
  });

  useEffect(() => {
    if (slug) {
      fetchData();
    }
  }, [slug]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`/blog/bySlug/${slug}`);

      if (response?.data?.success) {
        setBlogData(response?.data?.data);
        setSelectedTags(response?.data?.data?.tags);
      } else {
        console.error("Error fetching data:", response?.data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    if (BlogData && Object.keys(BlogData).length !== 0) {
      setFormData({
        title: BlogData.title || "",
        categories: BlogData.categories || "",
        author: BlogData.author || "",
      });
      setDetails(BlogData?.details);
    }
  }, [BlogData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setImageLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_UPLOAD_PRESET); // Replace "your_upload_preset" with your Cloudinary upload preset name

    // Upload image to Cloudinary
    axios
      .post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
        formData
      )
      .then((res) => {
        const imageUrl = cloudinaryCore.url(res.data.public_id, {
          quality: "100", // Specify custom quality (e.g., 50 for 50%)
          fetch_format: "jpg", // Specify custom output format (e.g., jpg, png)
          // Add any other custom parameters as needed
          // For example:
          width: 750,
          height: 500,
          // crop: "fill",
        });

        if (!imageUrl) {
          toast.error("Error in uploading Image,Please try again");
          setImage(null);
          setImageLoading(false);
        }
        if (res?.status === 200) {
          toast.success("Image uploaded Successfully");
          setImageLoading(false);
          setImage(imageUrl);
        }
      })
      .catch((error) => {
        toast.error(error);
        console.error("Error uploading image:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title) {
      toast.error("Title is required");
      return;
    }

    if (selectedTags.length === 0) {
      toast.error("Tags are required");
      return;
    }
    if (!formData.author) {
      toast.error("Author Name is required");
      return;
    }
    if (!formData.categories) {
      toast.error("Categorie is required");
      return;
    }
    if (!slug && !image) {
      toast.error("Image is required");
      return;
    }
    if (!details) {
      toast.error("Details are required");
      return;
    }
    setDisabled(true);

    const slugString = formData.title
      .trim()
      .toLowerCase()
      .replace(/[^\w\s]/g, "_") // Replace special characters with underscores
      .replace(/\s+/g, "_"); // Replace spaces with underscores

    // Remove duplicate underscores caused by consecutive special characters or spaces
    const cleanSlugString = slugString.replace(/_+/g, "_");
    if (!cleanSlugString) {
      toast.error("Somthing gone wrong! Please Enter Title again!");
      return;
    }
    const postData = {
      title: formData.title,
      slug: cleanSlugString,
      details: details,
      categories: formData.categories,
      tags: selectedTags,
      author: formData.author,
      image: image,
    };

    try {
      const response = slug
        ? await axiosInstance.put(`/blog/action/edit/${BlogData?._id}`, postData, {})
        : await axiosInstance.post(`/blog/action/post`, postData, {});

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        navigate("/all-blogs");
        setFormData({
          title: "",
          categories: "",
          author: "",
        });
        setDetails("");
        setDisabled(false);
      } else {
        toast.error(slug ? "Error in updating blog" : "Error in posting blog");
      }
    } catch (error) {
      toast.error("Error posting blog:", error);
      console.error("Error posting blog:", error);
    }
  };

  const top100Films = [
    { title: "Development" },
    { title: "Consultancy" },
    { title: "Finance" },
    { title: "Agency" },
    { title: "Business" },
    { title: "Corporate" },
    { title: "Development" },
  ];

  const Categories = [
    { label: "Business Solution" },
    { label: "Marketing Planning" },
    { label: "SEO Consulting" },
    { label: "Project Management" },
    { label: "Business Development" },
    { label: "Marketing Plan" },
  ];

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={2}>
        <MDBox mt={2}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {slug ? "Edit Blog Post" : "Create a New Blog Post"}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component="form" role="form" mx={2}>
          <MDBox mt={2} lineHeight={1}>
            <MDTypography htmlFor="title" component="label" color="text">
              Title:
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDInput
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              required
            />
          </MDBox>

          <MDBox mt={2} display="flex" alignItems="center" justifyContent="space-between" fullWidth>
            <MDBox mt={2} flex="1">
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="tag" component="label" color="text">
                  Tag:
                </MDTypography>
              </MDBox>
              <MDBox mb={1} flex="1">
                <Autocomplete
                  multiple
                  id="tags-filled"
                  value={selectedTags}
                  options={top100Films.map((option) => option.title)}
                  onChange={(event, value) => setSelectedTags(value)}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} variant="filled" />}
                />
              </MDBox>
            </MDBox>
            <MDBox mt={2} ml={5} flex="1">
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="author" component="label" color="text">
                  Author:
                </MDTypography>
              </MDBox>
              <MDBox mb={1} flex="1">
                <MDInput
                  type="text"
                  id="author"
                  name="author"
                  value={formData.author}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox display="flex" alignItems="center" justifyContent="space-between">
            <MDBox flex="1">
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="image" component="label" color="text">
                  Image:
                </MDTypography>
              </MDBox>
              <MDBox mb={1} display="flex" alignItems="center">
                <MDInput
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleFileChange}
                  accept="image/*"
                  fullWidth
                  required
                />
                {imageLoading && (
                  <MDBox ml={2}>
                    <CircularProgress color="secondary" size={20} />
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
            <MDBox flex="1" ml={5}>
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="categories" component="label" color="text">
                  Categories:
                </MDTypography>
              </MDBox>
              <MDBox flex="1">
                <Autocomplete
                  id="combo-box-demo"
                  value={formData.categories}
                  options={Categories}
                  onChange={(e, value) => setFormData({ ...formData, categories: value?.label })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox mt={2} lineHeight={1}>
            <MDTypography htmlFor="details" component="label" color="text">
              Details:
            </MDTypography>

            <MDBox mt={2} lineHeight={1}>
              <ReactQuill
                id="details"
                value={details}
                onChange={(value) => setDetails(value)}
                modules={modules}
                formats={formats}
                placeholder="Enter details..."
              />
            </MDBox>
          </MDBox>
          <MDBox mt={5} mb={1}>
            <MDButton
              variant="gradient"
              fullWidth
              color="info"
              onClick={handleSubmit}
              disabled={disabled}
            >
              Post Blog
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default CreateBlogForm;
