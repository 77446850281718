import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import axiosInstance from "api/axiosInstance";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from "react-toastify";

const CreateBlogForm = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const slug = location?.state?.slug;
  const [jobData, setJobData] = useState({});
  const [disabled, setDisabled] = useState();
  const [department, setDepartment] = useState([]);
  const [details, setDetails] = useState();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    employeeType: "",
    department: "",
    location: "",
    experience: "",
  });

  useEffect(() => {
    if (slug) {
      fetchData();
    }
    fetchDepartmets();
  }, [slug]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`/job/getById/${slug}`);

      if (response?.data?.success) {
        setJobData(response?.data?.data);
      } else {
        console.error("Error fetching data:", response?.data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  function transformDepartments(departments) {
    return departments.map((department) => {
      return { label: department.departmentName };
    });
  }
  const fetchDepartmets = async () => {
    try {
      const response = await axiosInstance.get(`/job/getAllDepartment`);

      if (response?.data?.success) {
        console.log("d", response?.data?.data);

        setDepartment(transformDepartments(response?.data?.data));
      } else {
        console.error("Error fetching data:", response?.data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    if (jobData && Object.keys(jobData).length !== 0) {
      setFormData({
        title: jobData.title || "",
        description: jobData.description || "",
        employeeType: jobData.employmentType || "",
        department: jobData.department || "",
        location: jobData.location || "",
        experience: jobData.experience || "",
      });
      setDetails(jobData?.requirements);
    }
  }, [jobData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title) {
      toast.error("Title is required");
      return;
    }

    if (!formData.description) {
      toast.error("Description is required");
      return;
    }
    if (!formData.location) {
      toast.error("Location  is required");
      return;
    }
    if (!formData.experience) {
      toast.error("Experience  is required");
      return;
    }
    if (!formData.employeeType) {
      toast.error("Employee Type is required");
      return;
    }
    if (!formData.department) {
      toast.error("Department is required");
      return;
    }

    if (!details) {
      toast.error("Details are required");
      return;
    }
    setDisabled(true);

    const slugString = formData.title
      .trim()
      .toLowerCase()
      .replace(/[^\w\s]/g, "_") // Replace special characters with underscores
      .replace(/\s+/g, "_"); // Replace spaces with underscores

    // Remove duplicate underscores caused by consecutive special characters or spaces
    const cleanSlugString = slugString.replace(/_+/g, "_");
    if (!cleanSlugString) {
      toast.error("Somthing gone wrong! Please Enter Title again!");
      return;
    }
    const postData = {
      title: formData.title,
      description: formData.description,
      slug: cleanSlugString,
      requirements: details,
      department: formData.department,
      employmentType: formData.employeeType,
      location: formData.location,
      experience: formData.experience,
    };

    try {
      const response = slug
        ? await axiosInstance.put(`/job/update/${jobData?._id}`, postData, {})
        : await axiosInstance.post(`/job/post`, postData, {});

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        navigate("/all-jobs");
        setFormData({
          title: "",
          description: "",
          employeeType: "",
          department: "",
          location: "",
          experience: "",
        });
        setDetails("");
        setDisabled(false);
      } else {
        toast.error(slug ? "Error in updating blog" : "Error in posting blog");
      }
    } catch (error) {
      toast.error("Error posting blog:", error);
      console.error("Error posting blog:", error);
    }
  };

  const top100Films = [
    { title: "Development" },
    { title: "Consultancy" },
    { title: "Finance" },
    { title: "Agency" },
    { title: "Business" },
    { title: "Corporate" },
    { title: "Development" },
  ];

  const Categories = [{ label: "Full Time" }, { label: "Part Time" }, { label: "Freelancer" }];

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={2}>
        <MDBox mt={2}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              {slug ? "Edit Job Post" : "Add a New Job Post"}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component="form" role="form" mx={2}>
          <MDBox mt={2} lineHeight={1}>
            <MDTypography htmlFor="title" component="label" color="text">
              Job Title:
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDInput
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              required
            />
          </MDBox>
          <MDBox mt={2} lineHeight={1}>
            <MDTypography htmlFor="title" component="label" color="text">
              Job Description:
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDInput
              type="text"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              required
            />
          </MDBox>
          <MDBox mt={2} display="flex" alignItems="center" justifyContent="space-between" fullWidth>
            <MDBox mt={2} flex="1">
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="author" component="label" color="text">
                  Experience:
                </MDTypography>
              </MDBox>
              <MDBox mb={1} flex="1">
                <MDInput
                  type="text"
                  id="experience"
                  name="experience"
                  placeholder="ex.-Fresher"
                  value={formData.experience}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </MDBox>
            </MDBox>
            <MDBox mt={2} ml={5} flex="1">
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="author" component="label" color="text">
                  Location:
                </MDTypography>
              </MDBox>
              <MDBox mb={1} flex="1">
                <MDInput
                  type="text"
                  id="location"
                  name="location"
                  placeholder="ex.-Indore"
                  value={formData.location}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox mt={2} display="flex" alignItems="center" justifyContent="space-between" fullWidth>
            <MDBox flex="1">
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="categories" component="label" color="text">
                  Employee Type:
                </MDTypography>
              </MDBox>
              <MDBox flex="1">
                <Autocomplete
                  id="combo-box-demo"
                  value={formData.employeeType}
                  options={Categories}
                  onChange={(e, value) => setFormData({ ...formData, employeeType: value?.label })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </MDBox>
            </MDBox>
            <MDBox flex="1" ml={5}>
              <MDBox mt={2} lineHeight={1}>
                <MDTypography htmlFor="categories" component="label" color="text">
                  Department:
                </MDTypography>
              </MDBox>
              <MDBox flex="1">
                <Autocomplete
                  id="combo-box-demo"
                  value={formData.department}
                  options={department}
                  onChange={(e, value) => setFormData({ ...formData, department: value?.label })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mt={2} lineHeight={1}>
          <MDTypography htmlFor="details" component="label" color="text">
            Details:
          </MDTypography>

          <MDBox mt={2} lineHeight={1}>
            <ReactQuill
              id="details"
              value={details}
              onChange={(value) => setDetails(value)}
              modules={modules}
              formats={formats}
              placeholder="Enter details..."
            />
          </MDBox>
        </MDBox>
        <MDBox mt={5} mb={1}>
          <MDButton
            variant="gradient"
            fullWidth
            color="info"
            onClick={handleSubmit}
            disabled={disabled}
          >
            Post Blog
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default CreateBlogForm;
