import Tables from "layouts/tables";
import Profile from "layouts/profile";
import QuoteForm from "layouts/quoteForm";
import ContactForm from "layouts/contactForm";
import Subscription from "layouts/subscription";
import JobData from "layouts/jobPage/viewJobs";
import JobApplication from "layouts/jobApplicationForms/viewJobs";
import Videos from "layouts/videoSection/viewVideoData";
import PostBlog from "layouts/viewBlogs";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Quote Form",
    key: "quote-form",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/quote-form",
    component: <QuoteForm />,
  },
  {
    type: "collapse",
    name: "Conatct Form",
    key: "contact-form",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/contact-form",
    component: <ContactForm />,
  },
  {
    type: "collapse",
    name: "Newsletter",
    key: "newsletter",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/newsletter",
    component: <Subscription />,
  },
  {
    type: "collapse",
    name: "Call Back Form",
    key: "call-back-form",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/call-back-form",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Blogs",
    key: "all-blogs",
    icon: <Icon fontSize="small">Blog</Icon>,
    route: "/all-blogs",
    component: <PostBlog />,
  },
  {
    type: "collapse",
    name: "Job",
    key: "all-jobs",
    icon: <Icon fontSize="small">Job</Icon>,
    route: "/all-jobs",
    component: <JobData />,
  },
  {
    type: "collapse",
    name: "Job Applications",
    key: "job-applications",
    icon: <Icon fontSize="small">Job</Icon>,
    route: "/job-applications",
    component: <JobApplication />,
  },
  {
    type: "collapse",
    name: "Addvideo",
    key: "add-video",
    icon: <Icon fontSize="small">Videos</Icon>,
    route: "/add-video",
    component: <Videos />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
];

export default routes;
