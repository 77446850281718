/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import axiosInstance from "api/axiosInstance";
import { useState, useEffect } from "react";
import { API_URL } from "../../../../url";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
export default function Applications() {
  const [videoData, setVideoData] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [disabled, setDisabled] = useState();
  const [Data, setData] = useState({
    title: "",
    link: "",
  });
  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...Data,
      [name]: value,
    });
  };
  const editItem = (video) => {
    if (video) {
      setData({ title: video.title || "", link: video.link || "" });
      setVideoId(video?._id);
    }
  }; // Empty dependency array ensures that the effect runs only once
  useEffect(() => {
    if (page) {
      fetchData();
    }
  }, [page]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_URL}/getvideoslink`);
      const data = await response.json();

      if (data?.success) {
        setVideoData(data?.data);
        setTotalCount(data?.totalCount);
      } else {
        console.error("Error fetching data:", data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Data.title) {
      toast.error("Title is required");
      return;
    }

    if (!Data.link) {
      toast.error("link is required");
      return;
    }

    setDisabled(true);

    const postData = {
      title: Data.title,
      link: Data.link,
    };

    try {
      const response = videoId
        ? await axiosInstance.put(`/video/${videoId}`, postData, {})
        : await axiosInstance.post(`/video`, postData, {});

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setData({
          title: "",
          link: "",
        });
        fetchData();
        setVideoId(null);
        setDisabled(false);
      } else {
        toast.error(videoId ? "Error in updating video data" : "Error in posting video data");
      }
    } catch (error) {
      toast.error("Error posting video data:", error);
      setDisabled(false);
      console.error("Error posting video data:", error);
    }
  };
  const deleteItem = async (id) => {
    const confirmed = window?.confirm("Are you sure want to delete?");

    if (confirmed) {
      const res = await axiosInstance.delete(`/video/${id}`, {});

      if (res?.data?.success) {
        toast.success(res?.data?.message);
        fetchData();
      }
    }
  };
  const postVideo = (
    <MDBox
      lineHeight={1}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
        margin: "0 20px",
        flexWrap: { lg: "nowrap", xs: "wrap" },
        width: "100%",
      }}
    >
      <TextField
        name="title"
        label="video Title"
        variant="outlined"
        margin="normal"
        sx={{
          width: "40%",
          minWidth: "20%",
        }}
        value={Data?.title}
        onChange={handleInputChange}
        required
      />
      <TextField
        name="link"
        label="video link"
        variant="outlined"
        margin="normal"
        sx={{
          width: "40%",
          minWidth: "20%",
        }}
        value={Data?.link}
        onChange={handleInputChange}
        required
      />
      <MDButton variant="gradient" color="info" onClick={handleSubmit} disabled={disabled}>
        {videoId ? "Edit Video data" : "Post video Data"}
      </MDButton>
    </MDBox>
  );

  const rows = videoData
    ? videoData.map((video) => ({
        Title: (
          <MDBox lineHeight={1} textAlign="left">
            <Tooltip title={video.title} arrow>
              <MDTypography
                component="span"
                variant="caption"
                color="text"
                fontWeight="medium"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main", // Change to the desired hover color
                  },
                }}
              >
                {video.title.length > 60 ? `${video.title.slice(0, 60)}...` : video.title}
              </MDTypography>
            </Tooltip>
          </MDBox>
        ),
        Link: (
          <MDBox lineHeight={1} textAlign="left">
            <Tooltip title={video.link} arrow>
              <MDTypography
                component="span"
                variant="caption"
                color="text"
                fontWeight="medium"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  "&:hover": {
                    color: "primary.main", // Change to the desired hover color
                  },
                }}
              >
                <a href={video.link} target="_blank" rel="noopener noreferrer">
                  {video.link.length > 60 ? `${video.link.slice(0, 60)}...` : video.link}
                </a>
              </MDTypography>
            </Tooltip>
          </MDBox>
        ),
        Action: (
          <MDBox lineHeight={1} textAlign="left" display="flex" alignItems="center" gap="10px">
            <MDButton variant="gradient" color="info" fullWidth onClick={() => editItem(video)}>
              Edit
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => deleteItem(video._id)}
            >
              Delete
            </MDButton>
          </MDBox>
        ),
      }))
    : [];

  return {
    columns: [
      { Header: "Title", accessor: "Title", width: "40%", align: "left" },
      { Header: "Link", accessor: "Link", width: "40%", align: "left" },
      { Header: "Action", accessor: "Action", align: "center" },
    ],

    rows: rows,
    totalCount: totalCount,
    pagecount: pageSize,
    setPage: setPage,
    postVideo,
  };
}
