import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { API_URL } from "url";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const submitData = async (event) => {
    event.preventDefault();

    if (!email) {
      setError("Please enter your email");
      return;
    }
    setError("");
    setSubmitButtonDisabled(true);
    setMessage("");

    try {
      const response = await fetch(`${API_URL}/auth/forgotPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (data.success) {
        setSubmitButtonDisabled(false);
        setMessage(data?.message);
        setEmail("");
      } else {
        setSubmitButtonDisabled(false);
        setError(data.message || "Error sending reset email. Please try again.");
      }
    } catch (error) {
      setSubmitButtonDisabled(false);
      setError(error?.response?.data?.messsage || "An error occurred. Please try again.");
      console.error("Fetch error:", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={submitData}>
            <MDBox mb={2}>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                style={{ height: "50px" }}
                name="email"
                value={email}
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                disabled={submitButtonDisabled}
              >
                Send Reset Link
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1}>
              {message && <p style={{ color: "green" }}>{message}</p>}
              {error && <p style={{ color: "red" }}>{error}</p>}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
